import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/container/container";
import ServicesCard from "../components/services card/services-card";
import Flex from "../components/flexContainer";

// Markup
const NousJoindre = () => {
  return (
    <Layout>
      <Container>
        <h1>Nos services</h1>
        <Flex>
          <ServicesCard givenTitle="Ménage après construction" givenList={[]} />
          <ServicesCard
            givenTitle="Arbustes"
            givenList={[
              "Préparation hiver couper",
              "Petits arbustes qui nuisent",
            ]}
          />
          <ServicesCard givenTitle="Lavage" givenList={["Lavage à pression"]} />
          <ServicesCard
            givenTitle="Réparation Pavé-uni"
            givenList={["Vider et remplacer les joints"]}
          />
          <ServicesCard
            givenTitle="Deck en bois"
            givenList={["Décapage de deck en bois"]}
          />
          <ServicesCard
            givenTitle="Peinture"
            givenList={["Sablage de patio", "Teinture ou vernis marche"]}
          />
          <ServicesCard
            givenTitle="Petite réparation"
            givenList={[
              "Petit crépis réparation",
              "Joint silicone fenêtre",
              "Et autre",
            ]}
          />
        </Flex>
      </Container>
    </Layout>
  );
};

export default NousJoindre;
