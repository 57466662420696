import * as React from "react";
import * as styledComponents from "./servicescard.module.scss";

// Markup
const ServicesCard = ({ givenTitle, givenList }) => {
  return (
    <article className={styledComponents.serviceCard}>
      <h5>{givenTitle}</h5>
      <ul>
        {givenList.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </article>
  );
};
export default ServicesCard;
