import * as React from "react";
import styled from "styled-components";

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 25px;
  margin-bottom: 40px;
`;

// Markup
const Flex = ({ children }) => {
  return <FlexContainer>{children}</FlexContainer>;
};
export default Flex;
